export const columns = [
	{ id: 'image', label: 'Articol', minWidth: 180 },
	{ id: 'title', label: '', minWidth: 300 },
	{ id: 'colors', label: '', width: 0 },
	{
		id: 'price',
		label: 'Preț',
		minWidth: 170,
		align: 'right',
	},
	{
		id: 'quantity',
		label: 'Cantitate',
		minWidth: 170,
		align: 'right',
	},
	{
		id: 'total',
		label: 'Total',
		minWidth: 170,
		align: 'right',
	},
];
