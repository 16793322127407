const states = [
	'Bucuresti',
	'Alba',
	'Arad',
	'Arges',
	'Bacau',
	'Bihor',
	'Bistrita-Nasaud',
	'Botosani',
	'Braila',
	'Brasov',
	'Buzau',
	'Calarasi',
	'Caras-Severin',
	'Cluj',
	'Constanta',
	'Covasna',
	'Dambovita',
	'Dolj',
	'Galati',
	'Giurgiu',
	'Gorj',
	'Harghita',
	'Hunedoara',
	'Ialomita',
	'Iasi',
	'Maramures',
	'Mehedinti',
	'Mures',
	'Neamt',
	'Olt',
	'Prahova',
	'Salaj',
	'Satu-Mare',
	'Sibiu',
	'Suceava',
	'Teleorman',
	'Timis',
	'Tulcea',
	'Valcea',
	'Vaslui',
	'Vrancea',
	'Ilfov',
];

export default states;
