export const frameColors = [
	'natur (lemn natural)',
	'roșu',
	'roz',
	'albastru marin',
	'portocaliu',
	'galben',
	'verde',
];

export const framePrice = 55.0;
