const colors = [
	{
		title: 'Roșu',
		color: '#ef233c',
		summary: 'pasiune, vitalitate, energie creatoare',
		desc: 'Roșul este adesea asociat cu pasiunea și vitalitatea, dar și cu puterea și determinarea. Dintr-un punct de vedere spiritual, roșul poate simboliza focul interior, curajul și energia creatoare. Poate reprezenta și dragostea divină și compasiunea.',
	},
	{
		title: 'Albastru',
		color: '#3a86ff',
		summary: 'profunzime, încredere, devotament',
		desc: 'Albastrul este culoarea cerului și a mării, sugerând infinitatea și profunzimea. În context spiritual, albastrul poate reprezenta încrederea, liniștea și calmul minții. Este adesea asociat cu înțelepciunea și devotamentul spiritual.',
	},
	{
		title: 'Verde',
		color: '#0a9396',
		summary: 'vindecare, echilibru, regenerare',
		desc: 'Verdele este culoarea naturii și a vieții. Este adesea considerat culoarea vindecării și a echilibrului. În spiritualitate, verdele poate simboliza înflorirea spirituală, regenerarea și armonie. Poate sugera, de asemenea, încredere în procesul de creștere și transformare.',
	},
	{
		title: 'Galben',
		color: '#fff185',
		summary: 'claritate, înțelegere, optimism',
		desc: 'Galbenul este culoarea luminii și a energiei solare. Într-un context spiritual, galbenul poate simboliza iluminarea și înțelegerea interioară. Reprezintă optimismul, bucuria și claritatea mentală. Poate reprezenta și căutarea înțelepciunii și cunoașterea divină.',
	},
	{
		title: 'Portocaliu',
		color: '#f48c06',
		summary: 'creativitate, expresie liberă a sinelui, explorare interioară',
		desc: 'Portocaliul combină energia roșului cu bucuria galbenului. În spiritualitate, portocaliul poate simboliza entuziasmul, creativitatea și expresia liberă a sinelui. Poate reprezenta și căutarea sensului vieții și a explorării interioare.',
	},
	{
		title: 'Mov',
		color: '#3a0ca3',
		summary: 'evoluție spirituală, intuiție, integritate',
		desc: 'Movul este o culoare misterioasă și profundă. În context spiritual, movul poate reprezenta transformarea și evoluția spirituală. Este adesea asociat cu intuiția și conexiunea cu planurile superioare de conștiință. Movul poate simboliza, de asemenea, vindecarea și echilibrul interior.',
	},
	{
		title: 'Roz',
		color: '#ffc8dd',
		summary: 'compasiune, blândeţe, acceptare',
		desc: 'Rozul este culoarea iubirii și a compasiunii. Într-un context spiritual, rozul poate simboliza dragostea divină, bunătatea și îngrijirea față de ceilalți. Este adesea asociat cu iertarea și acceptarea necondiționată.',
	},
	{
		title: 'Maro',
		color: '#9e2a2b',
		summary: 'stabilitate, siguranță, autenticitate',
		desc: 'Maroul este culoarea pământului și a stabilității. În spiritualitate, maroul poate simboliza înrădăcinarea și siguranța interioară. Reprezintă încrederea în procesul vieții și a conexiunii cu energiile fundamentale ale universului.',
	},
	{
		title: 'Negru',
		color: '#000000',
		summary: 'transformare, renaștere, autocunoaștere',
		desc: 'Negru este culoarea misterului și a necunoscutului. În context spiritual, negrul poate simboliza transformarea și renăscerea. Este adesea asociat cu regenerarea și cu procesele profunde de introspecție și autocunoaștere.',
	},
	{
		title: 'Alb',
		color: '#f4f1de',
		summary: 'puritate, iluminare spirituală, înțelepciune',
		desc: 'Albul este culoarea purității și a luminii divine. În spiritualitate, albul poate reprezenta puritatea sufletului și iluminarea spirituală. Este asociat cu înțelepciunea divină, claritatea mentală și starea de iluminare.',
	},
];

export default colors;
