import Goethe from '../images/Goethe.webp';
import ML from '../images/ML.webp';
import EvaHeller from '../images/EvaHeller.webp';
import KarenHaller from '../images/KarenHaller.webp';

const colorResearchers = [
	{
		researcher: 'Goethe',
		desc: 'Goethe a fost unul dintre primii care a studiat efectele psihologice ale culorilor! În cartea sa din 1810, "Teoria culorilor", el a propus pentru prima dată ideea că culorile nu erau doar fenomene fizice, ci aveau și efecte psihologice asupra oamenilor. El a susținut că fiecare culoare avea o caracteristică specifică și putea provoca răspunsuri emoționale și psihologice. Această lucrare a avut un impact semnificativ asupra domeniului creativ și este studiată și astăzi.',
		image: `url(${Goethe})`,
	},
	{
		researcher: 'Max Lüscher',
		desc: 'Max Lüscher a fost un psihoterapeut elvețian care a dezvoltat testul Lüscher al culorilor, o unealtă folosită pentru a măsura starea psihologică și trăsăturile de personalitate ale unei persoane, pe baza preferințelor ei de culoare. Acest test a fost tradus în mai mult de 30 de limbi străine, iar metoda lui de atribuire stare-culoare este folosită și acum în universități din toată lumea.',
		image: `url(${ML})`,
	},
	{
		researcher: 'Eva Heller',
		desc: 'Autoare și psiholoagă germană, Eva Heller, a scris cartea "Psihologia culorii: efecte și simbolistică", care explorează impactul psihologic al culorilor asupra ființelor umane. Aceasta evidențiază faptul că asocierea culorilor nu este una întâmplătoare, bazată strict pe gusturi, însă una bazată pe comportament și experiențe.',
		image: `url(${EvaHeller})`,
	},
	{
		researcher: 'Karen Haller',
		desc: 'Karen Haller este o expertă renumită în domeniul psihologiei aplicate a culorilor. Ea este specializată în înțelegerea modului în care culoarea afectează comportamentul și emoțiile umane. Totodată, are o pregătire în design interior și ajută oamenii să integreze culorile în spațiul lor. Ea și-a adunat toate cunoștiințele și a publicat cartea "The Little Book of Colour", unde explorează impactul culorilor asupra vieții umane, oferind o abordare practică.',
		image: `url(${KarenHaller})`,
	},
];
export default colorResearchers;
