import inspo1 from '../images/inspo1.webp';
import inspo2 from '../images/inspo2.webp';
import inspo3 from '../images/inspo3.webp';
import inspo4 from '../images/inspo4.webp';
import inspo6 from '../images/inspo6.webp';
import inspo7 from '../images/inspo7.webp';
import inspo8 from '../images/inspo8.webp';
import inspo9 from '../images/inspo9.webp';
import inspo10 from '../images/inspo10.webp';
import inspo11 from '../images/inspo11.webp';

import inspo14 from '../images/inspo14.webp';
import inspo15 from '../images/inspo15.webp';

export const inspoData = [
	{
		image: inspo14,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo15,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo4,
		heading: 'Slide One',
		desc: 'This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo8,
		heading: 'Slide Five',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo9,
		heading: 'Slide Seven',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo6,
		heading: 'Slide Seven',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo7,
		heading: 'Slide Eight',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo10,
		heading: 'Slide Eight',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo11,
		heading: 'Slide Eight',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo1,
		heading: 'Slide Two',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo2,
		heading: 'Slide Three',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},

	{
		image: inspo3,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
];
