export const infoCategories = [
	{
		name: 'Termeni și condiții',
		link: '/termeni-și-condiții',
	},
	{
		name: 'Politica de confidențialitate',
		link: '/politica-de-confidențialitate',
	},
	{
		name: 'FAQ',
		link: '/faq',
	},
	{
		name: 'Contact',
		link: '/contact',
	},
];

export const aboutUsCategories = [
	{
		name: 'Despre SELF',
		link: '/despre-self',
	},
	{
		name: 'Sustenabilitate',
		link: '/sustenabilitate',
	},
	{
		name: 'Inspirație',
		link: '/inspiratie',
	},
];
