import styles from '../styling/visual.images.gradient.module.scss';

const VisualImagesPickerGradient = () => {
	return (
		<div className={styles.container}>
			<div className={styles.firstContainer}></div>
			<div className={styles.secondContainer}></div>
		</div>
	);
};

export default VisualImagesPickerGradient;
