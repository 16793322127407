export const headline = 'Politica de confidențialitate';
export const confidentiality = [
	{
		title:
			'Respectarea dreptului la confidențialitate a datelor cu caracter personal',
		desc: 'Self Vision Design S.R.L., persoană juridică de naționalitate română, având sediul social în Bucuresti, Sector 3, Str. Nerva Traian, nr 27-33, Sc. B, Et. 1, Nr. 6, CUI: 50309275, Nr. Reg. Com. J40/12914/2024, prelucrează date cu caracter personal furnizate de către clienți/cumpărători în scopul derulării activității de comerț cu amănuntul prin intermediul magazinului online, www.selfposters.ro.\n\nSelf Vision Design S.R.L. respectă dreptul la confidențialitate. Această politică rezumă informațiile identificabile personal pe care le putem colecta prin intermediul website-ului și modul în care putem utiliza aceste date. Self Vision Design S.R.L. va culege numai informații pe care clientul/cumpărătorul le oferă voluntar.',
	},
	{
		title: 'Date cu caracter personal',
		desc: 'Datele cu caracter personal ce pot fi colectate sunt: numele, numărul de telefon, adresa de email, adresa sau alte date cu caracter personal, în măsura în care acestea sunt trimise de către vizitator prin intermediul formularului de comandă sau prin înregistrarea unui nou cont pe site. Atunci când clientul/cumpărătorul plasează o comandă, furnizează informații precum: produsul dorit, nume și prenume, adresa de livrare, detalii de facturare, metoda de plată, număr de telefon, datele cardului bancar etc. De asemenea, datele de mai sus sunt colectate de Self Vision Design S.R.L în măsura în care aceste date sunt comunicate de vizitator prin telefon.',
	},
	{
		title: 'Scopul prelucrării datelor cu caracter personal',
		desc: 'Self Vision Design SRL prin site-ul www.selfposters.ro colectează și prelucrează date cu caracter personal în următoarele scopuri:\n- în scop de vânzare produse online în urma comenzii primite de la client (prelucrare comenzi – preluare, validare, expediere, facturare).\n- în scopul soluționării problemelor de orice natură referitoare la o Comandă, Produse și Servicii.\n- asigurarea serviciilor de suport pentru răspunderea la întrebările clientului/cumpărătorului referitoare la comenzi, produse și servicii.\n- în scop de marketing, pentru îmbunătățirea permanentă a calității produselor și serviciilor oferite.\n- în scop de marketing – prin folosirea anumitor date ale clientului/cumpărătorului pentru a identifica comportamentul acestuia pe site și îmbunătățirea calității produselor și serviciilor oferite.\n- în anumite situații, putem baza activitățile de marketing pe interesul nostru legitim de a promova site-ul selfposters.ro și activitatea comercială. În orice situație în care folosim informații privind clientul/cumpărătorul pentru interesul nostru legitim, luăm toate măsurile necesare pentru ca drepturile și libertățile fundamentale ale clientului/cumpărătorului să nu fie afectate.\n- în scop de protecția a site-ului web și a utilizatorilor site-ului printare-online.ro față de atacuri cibernetice.\n- în scop de prevenire și detectare a tentativelor de fraudă, inclusiv transmiterea unor informații către autoritățile publice competente.\n',
	},
	{
		title: 'Cui transmitem datele cu caracter personal',
		desc: 'Self Vision Design S.R.L. poate transmite date cu caracter personal următoarelor categorii de destinatari:\n- furnizorilor serviciilor de curierat.\n- furnizorilor de servicii de plată/bancare.\n- furnizorilor de servicii IT.\n- furnizorilor de servicii de marketing (ex: Google Analytics, Google AdWords).\n- autorităților statului (inclusiv, dar fără a se limita la, autorități fiscale, autorități de protecție a consumatorilor, organele statului competente în materie penală etc.), ca urmare a unei obligații legale a Vânzătorului.\n\nAccesul la datele cu caracter personal ale clienților/cumpărătorilor de către persoane juridice de drept privat se realizează în conformitate cu prevederile legale privind protecția datelor și confidențialitatea informațiilor, în baza unor contracte încheiate cu aceștia.',
	},
	{
		title: 'Cât se păstrează datele cu caracter personal',
		desc: 'În cazul Utilizatorilor care au înaintat o comandă fără Cont personal pe site, datele cu caracter personal se păstrează timp de 1 an de la furnizarea lor către Self Vision Design S.R.L.\n\nClientul/Cumpărătorul are dreptul de a solicita oricând ștergerea anumitor informații sau închiderea contului, urmând ca Self Vision Design S.R.L. să dea curs acestor solicitări, sub rezerva păstrării anumitor informații inclusiv ulterior închiderii contului, în situațiile în care legislația aplicabilă sau interesele noastre legitime o impun.\n',
	},
	{
		title: 'În ce țări transferăm datele cu caracter personal',
		desc: 'Self Vision Design S.R.L. colectează și prelucrează date cu caracter personal pe teritoriul României. Cu toate acestea, este posibil să transferăm anumite date cu caracter personal unor entități din Uniunea Europeană sau în afara Uniunii, inclusiv în țări cărora Comisia Europeană nu le-a recunoscut un nivel adecvat de protecție a datelor.\n\nVom lua întotdeauna măsuri pentru a ne asigura că orice transfer internațional de date cu caracter personal este gestionat cu atenție, cu scopul de a vă proteja drepturile și interesele. Transferurile către furnizorii de servicii și alte părți terțe vor fi întotdeauna protejate prin angajamente contractuale și, după caz, prin alte garanții, cum ar fi clauzele contractuale standard emise de Comisia Europeană sau schemele de certificare, precum Scutul de confidențialitate pentru protecția datelor cu caracter personal transferate din interiorul UE către Statele Unite ale Americii.\n\nPentru a afla mai multe informații despre țările în care transferăm datele cu caracter personal, ne puteți contacta folosind adresa de e-mail selfposters@gmail.com.\n',
	},
	{
		title: 'Copyright',
		desc: 'Site-ul și toate elementele acestuia (grafică web, texte, fotografii etc.) sunt proprietatea Self Vision Design S.R.L și sunt apărate de legea pentru protecția drepturilor de autor. Folosirea fără acordul companiei Self Vision Design S.R.L a oricărui element cuprins în acest site se pedepsește conform legilor în vigoare.\n',
	},
	{
		title: 'Limitare de responsabilitate',
		desc: 'SELF nu se face responsabilă pentru prejudiciile (directe, indirecte, accidentale sau nu) ce rezultă din utilizarea sau incapacitatea de a utiliza informația prezentă pe site, precum și pentru erorile sau omisiunile în conținut care pot conduce la orice tip de pierderi. SELF sau oricare dintre angajați nu sunt responsabili pentru acțiunile pe care utilizatorul le întreprinde și care au rezultate negative sau care îi produc pierderi de vreun fel și nici pentru informațiile pe care utlizatorul le-a folosit și au condus la pierderi ale acestuia de orice fel, pentru informațiile folosite de terți sau de site-uri cu trimitere la site-ul selfposters.ro și nici pentru vreun software folosit pe site și care poate afecta sistemul de operare al utilizatorului sau care poate produce alte erori sau viruși.\n',
	},
	{
		title: 'Drepturi',
		desc: 'In conformitate cu prevederile Regulamentului 2016/679/UE, în calitate de subiect de date, orice client/cumpărător are următoarele drepturi:\n\n- dreptul la informare si acces;\n- dreptul la rectificare;\n- “dreptul de a fi uitat” sau dreptul la ștergere;\n- dreptul la restricționare;\n- dreptul de a retrage consimțământul;\n- dreptul la portabilitatea datelor;\n- dreptul la opozitie;\n- dreptul de a depune o plângere;\n',
	},
];

export default confidentiality;
