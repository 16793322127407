export const headline = 'Cum să meditez?';
export const meditationTips = [
	{
		title: 'Concentrare',
		desc: 'Concentrarea este una dintre cele mai importante elemente ale meditației. Îți eliberează mintea de numeroasele lucruri care provoacă stres și îngrijorare. Poți să-ți focusezi atenția asupra unui anumit obiect, o imagine, sau chiar respirația ta.',
	},
	{
		title: 'Respirație adâncă',
		desc: 'Meditația implică să respiri profund, folosind mușchiul dintre pieptul tău și abdomen, numit diafragma, pentru a-ți extinde plămânii. Scopul este să-ți încetinești respirația, să iei mai mult oxigen și să ajungi într-o stare relaxată de meditație.',
	},
	{
		title: 'Spațiu liniștit',
		desc: 'Dacă ești începător, meditația poate fi mai ușoară dacă te afli într-un loc liniștit. Încearcă să ai cât mai puține lucruri în jurul tău care te pot distrage. Pe măsură ce devii mai avansat în meditație, va deveni mai ușor să te concentrezi.',
	},
	{
		title: 'O poziție confortabilă',
		desc: 'Poți practica meditația fie că stai jos, te întinzi, mergi sau alte activități. Încearcă doar să fii confortabil și să-ți menții o postură bună, astfel încât să simți beneficiile ei la maxim.',
	},
	{
		title: 'O atitudine deschisă',
		desc: 'Lasă gândurile să treacă prin mintea ta fără a le judeca.',
	},
];
