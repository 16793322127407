import styles from '../styling/visual.images.module.scss';

const VisualImages = () => {
	return (
		<div className={styles.container}>
			<div className={styles.firstContainer}></div>
			<div className={styles.secondContainer}></div>
		</div>
	);
};

export default VisualImages;
